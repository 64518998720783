import React from "react";

export const ShowcaseOverviewSection = ({stats, texts, isHeight=false}) => {
    return (
        <>
            <div className={`${isHeight ? 'md:pt-96': 'md:pt-72'} bg-white py-6 container mx-auto`}>
                <div className='flex w-full flex-wrap py-4 sm:py-16'>
                    <div className="w-full sm:w-3/4 md:w-5/6 sm:border-l border-primary">
                        <div className='px-4 md:px-10'>
                            <div>
                                <h1 className="text-2xl sm:text-4xl font-bold text-primary leading-12">
                                    Overview
                                </h1>
                                <p className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                                    {texts.overview || "N/A"}
                                </p>
                            </div>
                            <div>
                                <h1 className="text-2xl sm:text-4xl font-bold text-primary leading-12">
                                    My Role
                                </h1>
                                <p className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                                    {texts.role || "N/A"}
                                </p>
                            </div>
                            <div>
                                <h1 className="text-2xl sm:text-4xl font-bold text-primary leading-12">
                                    End Result
                                </h1>
                                <p className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                                    {texts.results || "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/4 md:w-1/6 sm:order-first">
                        <dl className='sm:space-y-8 pt-2 px-4 sm:block grid grid-cols-2'>
                            <div className={stats.duration ? 'block' : 'hidden'}>
                                <dt className='font-semibold pb-2'>
                                    Duration
                                </dt>
                                <dd className='font-light'>
                                    { stats.duration || "N/A" }
                                </dd>
                            </div>
                            <div className={stats.year ? 'block' : 'hidden'}>
                                <dt className='font-semibold pb-2'>
                                    Year
                                </dt>
                                <dd className='font-light'>
                                { stats.year || "N/A" }
                                </dd>
                            </div>
                            <div className={stats.industry ? 'block' : 'hidden'}>
                                <dt className='font-semibold pb-2'>
                                    Industry
                                </dt>
                                <dd className='font-light'>
                                    { stats.industry || "N/A" }
                                </dd>
                            </div>
                            <div className={stats.income ? 'block' : 'hidden'}>
                                <dt className='font-semibold pb-2'>
                                    Income
                                </dt>
                                <dd className='font-light'>
                                    { stats.income || "N/A" }
                                </dd>
                            </div>
                            <div className={stats.company_size ? 'block' : 'hidden'}>
                                <dt className='font-semibold pb-2'>
                                    Company Size
                                </dt>
                                <dd className='font-light'>
                                    { stats.company_size || "N/A" }
                                </dd>
                            </div>
                            <div className={stats.roles && stats.roles.length > 0 ? 'block' : 'hidden'}>
                                <dt className='font-semibold pb-2'>
                                    My Role
                                </dt>
                                <dd className='font-light space-y-1'>
                                    {
                                        stats.roles.map((list, key) => (
                                            <span className='block' key={key}>{list}</span>
                                        ))
                                    }
                                </dd>
                            </div>
                            <div className={stats.tools_used && stats.tools_used.length > 0 ? 'block' : 'hidden'}>
                                <dt className='font-semibold pb-2'>
                                    Tools Used
                                </dt>
                                <dd className='font-light space-y-1'>
                                    {
                                        stats.tools_used.map((list, key) => (
                                            <span className='block' key={key}>{list}</span>
                                        ))
                                    }
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    )
}