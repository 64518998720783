import React from "react"
import BgImage from "../assets/img/download.svg"
import { Link } from "react-router-dom"

export const NotFound = () => {
    return (
        <>
            <div className="flex items-center justify-center min-h-screen bg-black text-white  bg-fixed bg-cover bg-bottom" style={{ backgroundImage: `url(${BgImage})`}}>
	            <div className="container">
		            <div className="row">
			            <div className="col-sm-8 offset-sm-2 text-gray-50 text-center -mt-56">
				            <div className="relative">
				                <h1 className="relative text-9xl text-primary tracking-tighter-less text-shadow font-sans font-bold">
					                <span>4</span><span>0</span><span>4</span>
                                </h1>
					            <span className="absolute top-0 -ml-12 text-gray-300 font-semibold">Oops!</span>
					        </div>
                            <h5 className="text-gray-300 font-semibold -mr-10 mt-3">Page not found</h5>
                            <p className="text-gray-100 mt-2 mb-12 px-4">we are sorry, but the page you requested was not found</p>
                            <Link to='/'
                                className="bg-primary my-6 px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg">
                                Got to Home
                            </Link>
			            </div>
		            </div>
	            </div>
            </div>
        </>
    )
}