import React from "react";
import { Layout } from "../layouts";
import UserImage from "../assets/img/user-image.jpeg"
import { CaseStudyBox } from "../components/CaseStudyBox";
import { Link } from "react-router-dom";
import { caseStudyLists } from "../contents/data";
import { motion } from 'framer-motion'

const variants = {
    slideIn: { x: '-100%', opacity: 0},
    hidden: { y: -20, opacity: 0, },
    show: { 
        y: 0,
        x: 0,
        opacity: 1,
    }
}

const xVariants = {
    left: { x: -150, transition: { staggerChildren: 0.1 }},
    right: { x: 150, transition: { staggerChildren: 0.1 }},
    animate: { x: 0, transition: { duration: 1, staggerChildren: 0.1, delayChildren: 0.3}},
    hidden: { y: -50, opacity: 0, },
    show: { 
        y: 0,
        opacity: 1,
    }
}
const Hero = () => {
    return (
        <div className="w-full bg-black py-12">
            <div className="h-full w-full mx-auto md:max-w-3xl py-12 flex flex-col items-center justify-center">
                <div className="mx-auto px-6 py-28 text-center bg-black w-full">
                    <motion.h1
                        variants={variants}
                        initial='hidden' whileInView='show'
                        transition={{ duration: 0.4, delay: 0.5}}
                        className="text-lg sm:text-3xl font-bold text-white leading-8 md:leading-12 lg:text-4xl"
                    >
                        Hello, I'm a <span className="text-primary">Digital Product Designer,</span> based in Lagos, Nigeria
                    </motion.h1>
                    <motion.p 
                        variants={variants}
                        initial='hidden' whileInView='show' 
                        transition={{ duration: 0.4, delay: 0.8}}
                        className="mt-6 text-white text-lg"
                    >
                        Currently crafting digital experiences at <span className="text-primary">Tech1M</span>
                    </motion.p>
                </div>
            </div>
        </div>
    )
}

const AboutContent = () => {
    return (
        <div className="bg-black text-white py-6">
            <div className="grid sm:grid-cols-2 lg:grid-cols-2 items-center mx-auto container border-primary border-t py-16">
                
                <motion.div 
                    variants={xVariants}
                    initial='left' whileInView='animate'
                    className="px-4 bg-right pb-8 sm:pb-20"
                >
                    <motion.div variants={xVariants} className="py-4">
                        <div className="">
                            <div>
                                <div className="py-4">
                                    <motion.h1 
                                        variants={xVariants}
                                        initial='hidden' whileInView='show' className="text-2xl font-bold text-white leading-10 lg:leading-12 lg:text-4xl">
                                        I enjoy solving problems and crafting great experience for  
                                        <span className="text-primary"> Digital Products.</span> 
                                    </motion.h1>
                                    <div className="py-4 md:py-8">
                                        <motion.p 
                                            variants={xVariants}
                                            initial='hidden' whileInView='show'
                                            className="leading-6 text-md"
                                        >
                                            While working on various projects in industries such as education, oil and gas, 
                                            cookware, technology and marketing, my research background has helped me thrive
                                            as a product designer. I enjoy making data-driven decisions while working on 
                                            various projects with stakeholders.
                                        </motion.p>
                                    </div>
                                    <div className="space-x-4 pt-5 flex">
                                        <Link
                                            className="text-white text-center bg-primary border-primary border rounded-full py-2 px-4 w-40 font-semibold about_link"
                                            to="/about"
                                        >
                                            About Me
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div 
                    variants={xVariants}
                    initial='right' whileInView='animate' className="flex sm:justify-end justify-center"
                >
                    <motion.div variants={xVariants} className="max-w-lg h-auto">
                        <motion.img variants={xVariants} src={UserImage} alt="Tobi Da-Silva" className="w-full" />
                    </motion.div>
                </motion.div>
            </div>
        </div>
    )
}
export const Home = () => {
    return (
        <>
            <Layout>

                <Hero />
                
                <div className="bg-black">
                    {
                        caseStudyLists.map((list, key) => (
                            <CaseStudyBox caseStudy={list} key={key} />
                        ))
                    }
                </div>

                <AboutContent />
            </Layout>
        </>
    )
}