export const overviewStats = {
    duration: "4 weeks",
    year: "2022",
    industry: "E-commerce",
    roles: ['UX Designer', 'UI Designer'],
    tools_used: ['Figma', 'Google Docs', 'Optimal Sort', 'Miro'],
}

export const overviewTexts = {
    overview: 'Mirror, which was founded in 2005, has grown to become one of the largest retail shopping outlets in Nigeria, with over 7 stores spread across the African continent.They would like to move some of their services online to make clothing accessible to all their clients while maintaining style and luxury across all age groups.',
    role: 'I served as both the UX and UI Designer on this project. It was a four-week project in which I had to design a mobile responsive website that met the needs of the company.',
    results: 'This project assisted in the creation of a fully responsive website design that can be implemented to help take mirrors online presence to the next level within the African fashion industry.'
}

export const mirrorProblemOptions = [
    {
        title: "User Interview",
        text: "To Learn what features would make shopping online more comfortable for them",
        svg: `<svg width="51" height="64" viewBox="0 0 51 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.1434 13.6378C12.1434 10.0208 13.5803 6.55201 16.1379 3.99442C18.6954 1.43684 22.1643 0 25.7812 0C29.3982 0 32.8671 1.43684 35.4246 3.99442C37.9822 6.55201 39.4191 10.0208 39.4191 13.6378C39.4191 17.2548 37.9822 20.7236 35.4246 23.2812C32.8671 25.8388 29.3982 27.2756 25.7812 27.2756C22.1643 27.2756 18.6954 25.8388 16.1379 23.2812C13.5803 20.7236 12.1434 17.2548 12.1434 13.6378ZM0.781612 56.3848C0.883805 49.8219 3.56264 43.5624 8.23986 38.9574C12.9171 34.3525 19.2176 31.7714 25.7812 31.7714C32.3449 31.7714 38.6454 34.3525 43.3226 38.9574C47.9999 43.5624 50.6787 49.8219 50.7809 56.3848C50.7888 56.8266 50.6677 57.2612 50.4325 57.6352C50.1973 58.0093 49.8581 58.3067 49.4565 58.4911C42.0289 61.8966 33.9523 63.6542 25.7812 63.6432C17.3379 63.6432 9.31586 61.8005 2.106 58.4911C1.70439 58.3067 1.36524 58.0093 1.13002 57.6352C0.894802 57.2612 0.773728 56.8266 0.781612 56.3848Z" fill="#F2994A"/></svg>`,
    },
    {
        title: "Competitive Analysis",
        text: "Aids us in gaining a competitive advantage and improving our approach and strategy.",
        svg: `<svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M35.4492 0C16.1184 0 0.449219 15.6692 0.449219 35C0.449219 54.3308 16.1184 70 35.4492 70C54.78 70 70.4492 54.3308 70.4492 35C70.4492 15.6692 54.78 0 35.4492 0ZM31.4108 16.1538C29.0903 16.1543 26.8025 16.7001 24.7317 17.7472C22.6609 18.7943 20.8652 20.3134 19.4894 22.182C18.1136 24.0506 17.1962 26.2164 16.8112 28.5047C16.4262 30.7929 16.5844 33.1397 17.273 35.3556C17.9615 37.5715 19.1613 39.5946 20.7754 41.2617C22.3894 42.9288 24.3727 44.1932 26.5653 44.953C28.7578 45.7128 31.0982 45.9466 33.3977 45.6357C35.6973 45.3248 37.8915 44.4778 39.8036 43.1631L47.0082 50.3641C47.2547 50.6286 47.5519 50.8408 47.8822 50.9879C48.2124 51.1351 48.5689 51.2142 48.9304 51.2206C49.2919 51.227 49.651 51.1605 49.9862 51.0251C50.3215 50.8896 50.626 50.6881 50.8817 50.4324C51.1373 50.1768 51.3389 49.8723 51.4743 49.537C51.6097 49.2018 51.6762 48.8427 51.6698 48.4812C51.6634 48.1197 51.5843 47.7632 51.4372 47.4329C51.29 47.1027 51.0778 46.8055 50.8133 46.559L43.6123 39.3544C45.1419 37.131 46.0348 34.5319 46.1945 31.8379C46.3542 29.1439 45.7747 26.4575 44.5185 24.069C43.2624 21.6805 41.3773 19.6807 39.0671 18.2856C36.757 16.8906 34.1095 16.1535 31.4108 16.1538Z" fill="#60A5FA"/></svg>`
    },
]

export const mirrorNeedOptions = [
    {
        title: "User Persona",
        text: "Create a persona to make our solution more human-centered and inclusive.",
        svg: `<svg width="71" height="68" viewBox="0 0 71 68" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.2045 13.4615C22.2045 9.89132 23.6227 6.46732 26.1473 3.94279C28.6718 1.41827 32.0958 0 35.666 0C39.2362 0 42.6602 1.41827 45.1848 3.94279C47.7093 6.46732 49.1276 9.89132 49.1276 13.4615C49.1276 17.0318 47.7093 20.4558 45.1848 22.9803C42.6602 25.5048 39.2362 26.9231 35.666 26.9231C32.0958 26.9231 28.6718 25.5048 26.1473 22.9803C23.6227 20.4558 22.2045 17.0318 22.2045 13.4615ZM49.1276 24.2308C49.1276 21.3746 50.2622 18.6354 52.2818 16.6158C54.3014 14.5962 57.0406 13.4615 59.8968 13.4615C62.753 13.4615 65.4922 14.5962 67.5118 16.6158C69.5314 18.6354 70.666 21.3746 70.666 24.2308C70.666 27.0869 69.5314 29.8261 67.5118 31.8458C65.4922 33.8654 62.753 35 59.8968 35C57.0406 35 54.3014 33.8654 52.2818 31.8458C50.2622 29.8261 49.1276 27.0869 49.1276 24.2308ZM0.666016 24.2308C0.666016 21.3746 1.80063 18.6354 3.82025 16.6158C5.83987 14.5962 8.57907 13.4615 11.4352 13.4615C14.2914 13.4615 17.0306 14.5962 19.0502 16.6158C21.0699 18.6354 22.2045 21.3746 22.2045 24.2308C22.2045 27.0869 21.0699 29.8261 19.0502 31.8458C17.0306 33.8654 14.2914 35 11.4352 35C8.57907 35 5.83987 33.8654 3.82025 31.8458C1.80063 29.8261 0.666016 27.0869 0.666016 24.2308ZM15.2404 43.4969C17.4299 40.0652 20.4494 37.2409 24.0195 35.2852C27.5897 33.3294 31.5953 32.3054 35.666 32.3077C39.0756 32.3046 42.4475 33.0216 45.5608 34.4119C48.6741 35.8021 51.4587 37.8342 53.7322 40.3752C56.0058 42.9161 57.7171 45.9086 58.754 49.1567C59.791 52.4048 60.1302 55.8353 59.7496 59.2236C59.7031 59.6445 59.5579 60.0486 59.3259 60.4028C59.0938 60.7571 58.7815 61.0517 58.4142 61.2626C51.4916 65.2347 43.6473 67.3193 35.666 67.3077C27.3917 67.3077 19.6199 65.1108 12.9178 61.2626C12.5505 61.0517 12.2382 60.7571 12.0062 60.4028C11.7741 60.0486 11.629 59.6445 11.5824 59.2236C10.9795 53.7192 12.2697 48.1736 15.2404 43.5005V43.4969Z" fill="#F9A8D4"/></svg>`,
    },
    {
        title: "Empathy Map",
        text: "Arrange data from user research to better understand our users.",
        svg: `<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.8713 1.60007C20.7396 1.16564 21.6971 0.939453 22.668 0.939453C23.6388 0.939453 24.5964 1.16564 25.4646 1.60007L42.108 9.92674C42.4613 10.1001 42.8746 10.1001 43.228 9.92674L56.1246 3.47674C57.0774 3.00071 58.1361 2.77608 59.2001 2.82416C60.2641 2.87224 61.2982 3.19144 62.2042 3.75146C63.1102 4.31148 63.858 5.09374 64.3767 6.024C64.8954 6.95426 65.1678 8.00164 65.168 9.06674V50.6834C65.168 53.0501 63.8313 55.2167 61.7113 56.2734L45.4613 64.3967C44.5939 64.8301 43.6376 65.0557 42.668 65.0557C41.6983 65.0557 40.742 64.8301 39.8746 64.3967L23.228 56.0734C23.0541 55.9863 22.8624 55.941 22.668 55.941C22.4735 55.941 22.2818 55.9863 22.108 56.0734L9.21464 62.5234C8.26171 63.0002 7.2027 63.2255 6.13821 63.1778C5.07372 63.1301 4.0391 62.811 3.13264 62.2509C2.22617 61.6908 1.47796 60.9082 0.959071 59.9775C0.440186 59.0469 0.167863 57.999 0.167969 56.9334V15.3167C0.167969 12.9501 1.50464 10.7834 3.6213 9.72674L19.8713 1.60341V1.60007ZM22.668 13.0001C23.331 13.0001 23.9669 13.2635 24.4357 13.7323C24.9046 14.2011 25.168 14.837 25.168 15.5001V43.0001C25.168 43.6631 24.9046 44.299 24.4357 44.7678C23.9669 45.2367 23.331 45.5001 22.668 45.5001C22.0049 45.5001 21.369 45.2367 20.9002 44.7678C20.4314 44.299 20.168 43.6631 20.168 43.0001V15.5001C20.168 14.837 20.4314 14.2011 20.9002 13.7323C21.369 13.2635 22.0049 13.0001 22.668 13.0001ZM45.168 23.0001C45.168 22.337 44.9046 21.7011 44.4357 21.2323C43.9669 20.7635 43.331 20.5001 42.668 20.5001C42.0049 20.5001 41.369 20.7635 40.9002 21.2323C40.4314 21.7011 40.168 22.337 40.168 23.0001V50.5001C40.168 51.1631 40.4314 51.799 40.9002 52.2678C41.369 52.7367 42.0049 53.0001 42.668 53.0001C43.331 53.0001 43.9669 52.7367 44.4357 52.2678C44.9046 51.799 45.168 51.1631 45.168 50.5001V23.0001Z" fill="#3B82F6"/></svg>`,
    },
]

export const mirrorSolutionOptions = [
    {
        title: "Card Sorting",
        text: "Understanding how users want website elements organized for proper comprehension.",
    },
    {
        title: "Information Architecture",
        text: "Designing the information, hierarchies and features that will appear on the website.",
    },
]