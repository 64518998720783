import React from 'react'
import { ShowcaseHero } from '../components/ShowcaseHero'
import { Layout } from '../layouts'
import HeroImg from '../assets/img/designlab/hero-img.png'
import AtWorkImg from '../assets/img/designlab/at_work.png'
import ChartImg from '../assets/img/designlab/rafiki.png'
import TeacherImg from '../assets/img/designlab/teacher-student.png'
import DashboardImg from '../assets/img/designlab/apps.png'
import { ShowcaseOverviewSection } from '../components/ShowcaseOverviewSection'
import { 
    designlabNeedOptions, designlabProblemOptions, designlabSolutionOptions, feedbacksContents, interviewContents, 
    overviewStats, overviewTexts, siteAuditContents 
} from '../contents/DesignLabShowcase'
import QuoteBg from '../assets/img/designlab/quote-bg.png'
import CompetitiveAnalysisImg from '../assets/img/designlab/competitive-analysis.png'
import EmpathyMapImg from '../assets/img/designlab/empathy-map.png'
import UserPersonaImg from '../assets/img/designlab/personas.png'
import BrainstormingImg from '../assets/img/designlab/hmw-questions.png'
import StatementTextImg from '../assets/img/designlab/statementtext.png'
import CommonGroundImg from '../assets/img/designlab/technical-goals-designlab.png'
import UserFlowImg from '../assets/img/designlab/user-flow.png'
import ScreenMockupImg from '../assets/img/designlab/screen-mockup.png'
import { ShowcasePagination } from '../components/ShowcasePagination'
import { caseStudyLists } from '../contents/data'


const QuoteSection = () => (
    <>
        <div className='relative'>
            <img src={QuoteBg} alt="Quote Background" />
            <div className='w-full absolute text-white top-2 sm:top-1'>
                <div className='max-w-4xl mx-auto sm:py-32 text-center px-2'>
                    <p className="py-2 sm:pt-6 text-sm sm:text-3xl font-bold text-white md:leading-12 lg:text-4xl">
                        "A dashboard is a visual display of the most important information, needed to achieve 
                        one or more objectives - Steve Few"
                    </p>
                </div>
            </div>
        </div>
    </>
)

const BriefHistorySection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    About Designlab
                </h1>
            </div>

            <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                <p className="pb-6">
                    Designlab was founded in 2012 with the mission of empowering creators and making learning 
                    more accessible.This they have accomplished by combining the power of the internet with 
                    the magic of one-on-one mentoring to create an invaluable educational experience that is 
                    affordable and accessible worldwide. 
                </p>
                <p className="pb-6">
                    To proceed with product improvement, they want to focus on developing a student dashboard 
                    area. Dashboards, according to design trends, should display data in a quick, 
                    easy-to-scan format with the most relevant details easily interpretable at a glance. 
                    Many students like to keep track of their progress in a timely and orderly manner, 
                    so having a dashboard can be a way to display all critical information to students 
                    before moving on to other parts of the application.
                </p>
            </div>

            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    Business KPI
                </h1>
            </div>
            <div className="py-4 md:py-12 leading-9 text-xl md:text-2xl">
                <p className="pb-6">
                    The project's key performance indicators are to create a student dashboard area that would contribute 
                    to:
                </p>
                <ul className="pb-6 list-disc ml-4">
                    <li>
                        the student being able to see information,
                    </li>
                    <li>
                        study the information, and 
                    </li>
                    <li>
                        carry out actions based on the data displayed.
                    </li>
                </ul>
                <p className="pb-6">
                    By accomplishing this target, it is expected that students would have better clarity as regarding their 
                    studies and learning outcomes thereby reducing confusion amongst students by at least 70%.
                </p>
            </div>
        </div>
    </>
)

const UnderstandingProblemSection = () => (
    <>
        <div className="bg-indigo-50 py-8 sm:py-24">
            <div className='container mx-auto'>
                <div className='py-2 text-center'>
                    <h1 className="text-2xl font-bold text-primary leading-12 lg:text-3xl">
                        Understanding the Problem
                    </h1>
                </div>

                <div>
                    <ul className='flex flex-wrap w-full pt-16'>
                    
                        {
                            designlabProblemOptions.map((list, key) => (
                                <li className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                    <div className='bg-white py-2 mx-4 rounded-md relative'>
                                        <div className='w-full absolute -top-8'>
                                            <div className='w-20 mx-auto overflow-x-hidden' dangerouslySetInnerHTML={{ __html: list.svg }}></div>
                                        </div>
                                        <div className='text-center py-6 px-2 sm:px-8'>
                                            <p className='text-xl text-gray-800 font-semibold py-4'>
                                                {list.title}
                                            </p>
                                            <p className='text-md px-4 font-normal text-gray-600'>
                                                {list.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </div>
    </>
)

const ResearchReportSection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Why Site Audit?
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Conducting a sight audit was a no brainer for me in terms of my research because this 
                        product is already being used by over 1000 students scattered across the globe. I believe 
                        a lot of insights can be obtained by looking into the product and its existing users 
                        before exploring what competitors might be doing                   
                    </p>
                </div>
                <div className='py-6'>
                    <div className='flex flex-wrap w-full'>
                        {
                            siteAuditContents.map((option, key) => (
                                <div className='w-full sm:w-1/2 my-8 sm:my-4' key={key}>
                                    <div className="h-full w-full">
                                        <div className="relative w-full flex items-center justify-center">
                                            <img src={option.img} className="mb-3 w-32 h-32 rounded-xl" alt="" />
                                        </div>
                                        <div className="my-3 px-1">
                                            <div className="mb-2 text-center py-1">
                                                <p className="text-lg font-bold text-navy-700">
                                                    {option.heading}
                                                </p>
                                            </div>
                                            <div className="mb-2 text-center md:px-8">
                                                <ul className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
                                                    {
                                                        option.contents.map((content, index) => (
                                                            <li className='my-2 list-item list-inside list-disc' key={index}>
                                                                {/* <span className='bg-gray-600 h-1 w-1 rounded-full'></span> */}
                                                                <span className='text-lg'>
                                                                    {content}
                                                                </span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                       
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <div className='p-4 sm:p-4 md:p-12 bg-orange-200 rounded-md relative'>
                        <div className='md:absolute w-32 h-auto sm:top-6 sm:right-16 mx-auto'>
                            <img src={AtWorkImg} alt='character' />
                        </div>
                        <div className='py-4 w-full max-w-5xl'>
                            <h2 className='text-xl md:text-2xl py-2'>My Insights From the Audit</h2>
                            <p className='text-gray-800 py-2'>
                                In conducting the audit on the existing application here were a few things i learnt;
                            </p>
                        </div>
                        <div className='w-full max-w-5xl'>
                            <ul>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>Position of icons need to be reimagined to aid user navigation</span>
                                    <p className='text-gray-800 py-2'>
                                        At present, some icons on the application are positioned in uncommon places that could pose a challenge 
                                        for users. For instance, relocating the notification icon to the top right corner of the visual layout could 
                                        enhance user navigation on the platform.
                                    </p>
                                </li>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>Having a student dashboard is an added advantage for students.</span>
                                    <p className='text-gray-800 py-2'>
                                        Considering the varying paths of students enrolled in Designlab's UX Academy and related programs, 
                                        the dashboard section can function as a space for learners to obtain a swift summary of their 
                                        advancement and outstanding assignments to be fulfilled each week.
                                    </p>
                                </li>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>Information is clustered</span>
                                    <p className='text-gray-800 py-2'>
                                        The undefined dashboard region for students results in a congested display of information within 
                                        the coursework section. By improving the information architecture of the dashboard area, the 
                                        arrangement of information can be optimized to minimize confusion.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-16'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Conducting User Interviews
                    </h1>
                </div>
                <div className="py-4 md:py-8">
                    <p className="pb-6 leading-9 text-xl md:text-2xl">
                        Conducting user interviews for this project was challenging yet gratifying. It was 
                        difficult because most students work part-time and have other obligations, but with 
                        the help of calendly, we were able to find times that worked for all ten participants.      
                    </p>
                    <div className='py-6'>
                        <div className='flex flex-wrap w-full'>
                            {
                                interviewContents.map((option, key) => (
                                    <div className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                        <div className="h-full w-full">
                                            <div className="relative w-full flex items-center justify-center">
                                                <img src={option.img} className="mb-3 w-32 h-32 rounded-xl" alt="" />
                                            </div>
                                            <div className="my-3 px-1">
                                                <div className="mb-2 text-center py-1">
                                                    <p className="text-lg font-bold text-navy-700">
                                                        {option.heading}
                                                    </p>
                                                </div>
                                                <div className="mb-2 text-center md:px-8">
                                                    <ul className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
                                                        {
                                                            option.contents.map((content, index) => (
                                                                <li className='my-2 list-item list-inside list-disc' key={index}>
                                                                    {/* <span className='bg-gray-600 h-1 w-1 rounded-full'></span> */}
                                                                    <span className='text-lg'>
                                                                        {content}
                                                                    </span>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        
                        </div>
                    </div>

                    <p className="pb-6 leading-9 text-xl md:text-2xl">
                        Interview Insights
                    </p>
                    <ul className="pl-4 sm:pl-8 list-disc list-inside leading-9 text-xl md:text-2xl">
                        <li>
                            8 out of 10 participants stated having a dashboard would help them understand what 
                            actions they need to carry out quickly
                        </li>
                        <li>
                            All participants indicated that the way information has been merged with another 
                            section within the LMS isn't the best solution at the moment
                        </li>
                        <li>
                            Especially for part-time students the current way in which progress reports are given 
                            is a bit frustrating and causes some level of anxiety
                        </li>
                    </ul>
                    <p className="py-6 leading-9 text-xl md:text-2xl">
                        Finally, I would say that carrying out this research helped in understanding some of the 
                        key elements that would help create a dashboard with a good user experience.
                    </p>
                </div>

                <div>
                    <div>
                        <div className='p-4 sm:p-4 md:p-12 bg-orange-200 rounded-md relative'>
                            <div className='md:absolute w-32 h-auto sm:top-6 sm:right-16 mx-auto'>
                                <img src={ChartImg} alt='character' />
                            </div>
                            <div className='py-4 w-full max-w-5xl'>
                                <h2 className='text-xl md:text-2xl py-2'>Challenges faced during the interview</h2>
                            </div>
                            <div className='py-4 w-full max-w-5xl text-base'>
                                <p className='text-gray-800 py-2'>
                                    Designlab is an online platform for learning that is designed to cater to students from different parts of 
                                    the world. Consequently, it was not feasible to conduct in-person interviews with the students. 
                                    Additionally, the timezone differences posed a challenge, making it difficult to schedule interviews 
                                    that were convenient for all students.
                                </p>

                                <p className='text-gray-800 py-2'>
                                    To address this issue, I utilised Calendly to select corresponding timezones that accommodated 
                                    the availability of the students. Moreover, many students had part-time jobs and other commitments, 
                                    making it necessary to conduct interviews during weekends or after work hours. As a result of these 
                                    constraints, the user interview process took three weeks to complete, which was longer than the one-week 
                                    timeline specified in the project.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Market Research and Competitive Analysis
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        What is going on in the edtech world? What are our rivals doing better? These were some of the 
                        questions I had as I began my investigation in the edtech business. In the table below, I've 
                        summarized some of my results.
                    </p>
                    <div className='py-6 max-w-5xl w-full mx-auto'>
                        <img alt='Competitive Analysis' src={CompetitiveAnalysisImg} />
                    </div>
                </div>
                <div>
                    <div>
                        <div className='p-4 sm:p-4 md:p-12 bg-orange-200 rounded-md relative'>
                            <div className='md:absolute w-32 h-auto sm:top-6 sm:right-16 mx-auto'>
                                <img src={AtWorkImg} alt='character' />
                            </div>
                            <div className='py-4 w-full max-w-5xl'>
                                <h2 className='text-xl md:text-2xl py-2'>Quick Learning From Competitor Analysis</h2>
                            </div>
                            <div className='w-full max-w-5xl'>
                                <ul>
                                    <li className='list-inside list-disc'>
                                        <span className='text-lg py-2'>Remote learning is the way for the future</span>
                                        <p className='text-gray-800 py-2'>
                                            The Covid pandemic has been instrumental in revolutionising the approach to teaching and 
                                            delivering education across all academic institutions. Thanks to the advancements in the Edtech 
                                            sector, it is now evident that remote learning is likely to persist as an alternative to 
                                            conventional learning methods.
                                        </p>
                                    </li>
                                    <li className='list-inside list-disc'>
                                        <span className='text-lg py-2'>Continuous improvement on the LMS.</span>
                                        <p className='text-gray-800 py-2'>
                                            An Edtech company's success is closely tied to the effectiveness of its Learning Management System 
                                            (LMS). As a diverse set of users with varying needs and abilities utilize the LMS for their 
                                            learning needs, it's crucial to enhance the design and user experience to ensure accessibility 
                                            for a broad range of users.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

const UserNeedsSection = () => (
    <>
        <div className="bg-indigo-50 py-8 sm:py-24">
            <div className='container mx-auto'>
                <div className='py-2 text-center'>
                    <h1 className="text-2xl font-bold text-primary leading-12 lg:text-3xl">
                        Defining the Problem and User Needs
                    </h1>
                </div>

                <div>
                    <ul className='flex flex-wrap w-full pt-16'>
                        {
                            designlabNeedOptions.map((list, key) => (
                                <li className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                    <div className='bg-white py-2 mx-4 rounded-md relative'>
                                        <div className='w-full absolute -top-8'>
                                            <div className='w-20 mx-auto overflow-x-hidden' dangerouslySetInnerHTML={{ __html: list.svg }}></div>
                                        </div>
                                        <div className='px-2 text-center py-6 sm:px-8'>
                                            <p className='text-xl text-gray-800 font-semibold py-4'>
                                                {list.title}
                                            </p>
                                            <p className='text-md px-4 font-normal text-gray-600'>
                                                {list.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </div>
    </>
)

const EmpathyMapSection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Using Empathy Mapping
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-2">
                        Using the information obtained from the user interview, I created an empathy map to help 
                        me better understand the needs of the user and their pattern when interviewed. 
                    </p>
                </div>
                <div className='py-6'>
                    <img alt='Empathy Map' src={EmpathyMapImg} />
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        User Persona
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Having a user persona was also vital because the students at designlab are divided into two groups of 
                        students (full time and part-time), so i created two separate user personas to guide my design.
                    </p>
                    <div className='py-6'>
                        <img alt='Competitive Analysis' src={UserPersonaImg} />
                    </div>
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Brainstorming
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        With my empathy map data and user persona in hand, I organized a meeting with 
                        two designers at designlab to discuss and come up with viable ideas for the feature 
                        design. Here are some of the various options we came up with.
                    </p>
                    <div className='py-6'>
                        <img alt='Brainstorming' src={BrainstormingImg} />
                    </div>
                </div>
            </div>
        </div>
    </>
)

const CommonGroundSection = () => (
    <>
        <div className="bg-white py-8 sm:py-20 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        What is the common ground?
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        At this stage, I was able to completely emphasize the user, business, and technological 
                        goals, as well as the areas where they intersect. This made addressing the problem 
                        statement based on the existing facts quite simple.
                    </p>
                </div>
                <div className='py-6'>
                    <img alt='What is the common ground?' src={CommonGroundImg} />
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        User Flow
                    </h1>
                </div>
                <div className="leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        To provide more context on how new and existing users would navigate the product, 
                        we came up with a user flow to demonstrate possible actions that may be performed 
                        using the dashboard.
                    </p>
                    <div className='py-2'>
                        <img alt='User Flow' src={UserFlowImg} />
                    </div>
                </div>
            </div>
        </div>
    </>
)

const DesignFidelitySection = () => (
    <>
        <div className="bg-white container mx-auto pb-8 sm:pb-20 px-4 sm:px-0">
            <div className='max-w-5xl mx-auto text-center'>
                <h1 className="text-3xl font-bold text-primary lg:text-4xl">
                    The Design
                </h1>
            </div>

            <div>
                <div className="py-4 leading-9 text-xl md:text-2xl w-full max-w-4xl mx-auto">
                    <p className="pb-6 text-center">
                        After a series of iterations from my designs and low fidelity wireframes, I used 
                        components and the existing style guide to create the initial version of the dashboard.
                    </p>
                </div>
                <div className='py-8 max-w-5xl w-full mx-auto'>
                    <img alt='Low Fidelity Design' src={ScreenMockupImg} />
                </div>
            </div>

            {
                designlabSolutionOptions.map((list, key) => (
                    <div className="flex flex-wrap items-center py-12" key={key}>
                        <div className="w-full sm:w-1/2">
                            <div className={`${key === 1 ? 'sm:pl-12' : ''}`}>
                                <h1 className='text-2xl py-2 font-semibold'>
                                    {list.title}
                                </h1>
                                <p className='text-lg py-2 text-gray-500'>
                                    {list.text}
                                </p>
                            </div>
                        </div>
                        <div className={`${key === 1 ? 'sm:order-first' : ''} w-full sm:w-1/2`}>
                            <div className='px-2 sm:px-6'>
                                <img alt={designlabSolutionOptions.title} src={list.image} />
                            </div>
                        </div>
                    </div>
                ))
            }

            <div className='pt-4'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Getting Feedback
                    </h1>
                </div>

                <div className='py-6 container mx-auto'>
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                        <iframe 
                            src="https://www.loom.com/embed/2af1789f58d64eb5813b8fe9e2befaf2?sid=6e46ef84-f1da-403c-88bb-e61582e6332f" 
                            frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen title='Videos'
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', }}
                        ></iframe>
                    </div>
                </div>

                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="py-6">
                        We conducted remote usability testing with available participants to obtain 
                        feedback from users directly for further iteration. Seven current designlab 
                        students took part in the testing. It was considerably easier to organize interviews 
                        because there was already a relationship with some of the user interview participants.
                    </p>
                </div>
                <div className='py-6'>
                    <div className='flex flex-wrap w-full'>
                        {
                            feedbacksContents.map((option, key) => (
                                <div className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                    <div className="h-full w-full">
                                        <div className="relative w-full flex items-center justify-center">
                                            <img src={option.img} className="mb-3 w-40 h-40 rounded-xl object-contain" alt="" />
                                        </div>
                                        <div className="my-3 px-1">
                                            <div className="mb-2 text-center py-1">
                                                <p className="text-lg font-bold text-navy-700">
                                                    {option.heading}
                                                </p>
                                            </div>
                                            <div className="mb-2 text-center md:px-8">
                                                <ul className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
                                                    {
                                                        option.contents.map((content, index) => (
                                                            <li className='my-2 list-item list-inside list-disc' key={index}>
                                                                {/* <span className='bg-gray-600 h-1 w-1 rounded-full'></span> */}
                                                                <span className='text-lg'>
                                                                    {content}
                                                                </span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                       
                    </div>
                    
                    <div className='py-4'>
                        <div>
                            <div className='p-4 sm:p-4 md:p-12 bg-orange-200 rounded-md relative'>
                                <div className='md:absolute w-32 object-contain h-auto sm:top-6 sm:right-16 mx-auto'>
                                    <img src={TeacherImg} alt='character' />
                                </div>
                                <div className='py-4 w-full max-w-5xl'>
                                    <h2 className='text-xl md:text-2xl py-2'>Areas To Improve From Usability Studies</h2>
                                    <p className='text-gray-800 py-2'>
                                        The method used to recruit participants for the usability studies was identical to that of the user 
                                        interviews, and remote testing was employed to gather user feedback. Based on my prior experience 
                                        with scheduling participants during the user interviews, I found it simpler to coordinate schedules 
                                        using Calendly to schedule mutually convenient times for myself and the participants. While the 
                                        study yielded positive results, areas that could be improved upon includes;
                                    </p>
                                </div>
                                <div className='w-full max-w-5xl'>
                                    <ul>
                                        <li className='list-inside list-disc'>
                                            <span className='text-lg py-2'>Make the Search function optimised</span>
                                            <p className='text-gray-800 py-2'>
                                                According to students, the search feature ought to go beyond just presenting resources and 
                                                instead broaden search outcomes to include the profiles of other students and mentors.
                                            </p>
                                        </li>
                                        <li className='list-inside list-disc'>
                                            <span className='text-lg py-2'>Course Report</span>
                                            <p className='text-gray-800 py-2'>
                                                The course report's existing layout caters to daily reports, but the effectiveness of such 
                                                frequent updates was questioned by participants, considering that many students are 
                                                part-time and have other obligations. As a solution, it was suggested that the reports 
                                                should be displayed on a weekly or monthly basis, making it simpler to monitor their 
                                                progress, regardless of their commitments outside of Designlab.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='py-8 md:my-12 max-w-6xl w-full mx-auto'>
                            <img alt='Low Fidelity Design' src={DashboardImg} />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Reflection and Next Steps
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Working on this project was a bit challenging because there were other features to design, 
                        but as the product roadmap was proposed, focusing on the student dashboard became a 
                        priority. I really loved interacting with the users during the interview since it helped 
                        me understand how to create a suitable dashboard.
                    </p>
                    <p className="pb-6">
                        My next steps would be as follows:
                    </p>
                    <ul className='space-y-4 list-disc pl-4 sm:pl-8 text-xl'>
                        <li>
                            Make iterations on the existing design based on feedback from usability testing
                        </li>
                        <li>
                            Meet with other designers within the team to present and critique the iterations made
                        </li>
                        <li>
                            Make final adjustments to the screen and other components
                        </li>
                        <li>
                            Hand Off Designs to the developers
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
)

const StatementSection = () => (
    <>
         <div>
            <div className='relative text-white'>
                <img alt='statement text' src={StatementTextImg} />
                <div className="absolute top-0 w-full">
                    <div className='max-w-4xl mx-auto w-full flex flex-col items-center py-1 sm:py-20'>
                        <h2 className='text-sm sm:text-2xl font-semibold leading-6'>Problem Statement</h2>
                        <p className='text-xs sm:text-2xl font-semibold sm:leading-6 sm:py-2 text-center'>
                            "How do you make a design decision that moves product forward to a larger audience and customer retention?"
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export const DesignLabShowcase = () => {
    return (
        <>
            <Layout>
                <ShowcaseHero name="Designlab Dashboard Feature" image={HeroImg} />
                <ShowcaseOverviewSection stats={overviewStats} texts={overviewTexts} />
                <QuoteSection />
                <BriefHistorySection />
                <UnderstandingProblemSection />
                <ResearchReportSection />
                <UserNeedsSection />
                <EmpathyMapSection />
                <StatementSection />
                <CommonGroundSection />
                <DesignFidelitySection />
                <ShowcasePagination data={caseStudyLists[4]} />
            </Layout>
        </>
    )
}