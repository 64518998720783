import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const Cursor = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const mouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener("mousemove", mouseMove);

        return () => {
            window.removeEventListener("mousemove", mouseMove);
        };
    }, []);

    return (
        <motion.div
            className="hidden md:inline-flex items-center justify-center rounded-full w-6 h-6 fixed border bg-primary"
            animate={{ x: position.x+10, y: position.y+10 }}
            style={{ zIndex: 1000}}
        ></motion.div>
    );
};

export default Cursor;
