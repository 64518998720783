import React from "react";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion'

const variants = {
    slideUp: { y: 100, opacity: 0},
    slideLeft: { x: -100, opacity: 0},
    slideRight: { x: 100, opacity: 0},
    hoverSlideRight: { x: 20},
    hidden: { y: -20, opacity: 0, },
    show: { 
        y: 0,
        x: 0,
        opacity: 1,
    }
}
const LinkDiv = ({ caseStudy, children }) => (
    <>
        {
            caseStudy.title === 'Sanaa' ? 
                <a title={caseStudy.title} href={caseStudy.link} target="_blank" rel="noreferrer" className="relative">
                    {children}
                </a> :
                <Link title={caseStudy.title} to={caseStudy.link} className="relative">
                    {children}
                </Link>
        }
        
    </>
)

export const CaseStudyBox = ({ caseStudy }) => {
    return (
        <>
            <div className="container mx-auto border-primary border-t py-16">
                <div className="flex justify-between items-center pb-8 px-4 sm:px-0">
                    <div className="text-white">
                        <motion.p variants={variants} initial='slideLeft' whileInView='show' className="text-xl font-bold py-2">
                            UX Case Study
                        </motion.p>
                        <motion.p variants={variants} initial='slideLeft' whileInView='show' className="text-xl font-medium py-2">
                            {caseStudy?.title}
                        </motion.p>
                    </div>
                    <div>
                        <LinkDiv caseStudy={caseStudy}>
                            <motion.span variants={variants} initial='slideLeft' whileInView='show' className="sr-only text-white text-sm">
                                {caseStudy?.title || 'N/A'}
                            </motion.span>
                            <motion.span variants={variants} whileHover='hoverSlideRight' initial='slideRight' whileInView='show' className="text-white arrow-link">
                                <motion.svg variants={variants} width="49" height="32" viewBox="0 0 49 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_412_2906" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="49" height="32">
                                        <path d="M33.727 31.272L31.102 28.6811L41.9088 17.8743H0.679688V14.1243H41.9088L31.102 3.35156L33.727 0.726562L48.9997 15.9993L33.727 31.272Z" fill="#F0E0CB"/>
                                    </mask>
                                    <g mask="url(#mask0_412_2906)">
                                        <rect x="0.679688" y="0.724609" width="48.32" height="30.55" fill="currentColor"/>
                                    </g>
                                </motion.svg>
                            </motion.span>
                        </LinkDiv>
                    </div>
                </div>

                <div className="w-full h-auto group">
                    <LinkDiv caseStudy={caseStudy}>
                        <motion.img 
                            variants={variants} initial='slideUp' whileInView='show'
                            src={caseStudy.image} 
                            alt={caseStudy.title} 
                            className="w-full"
                        />
                        <div className="group-hover:flex hidden">
                            <div className="bg-black h-20 md:h-24 py-2 px-2 md:px-6 hidden md:flex items-center absolute text-white bottom-0 w-full">
                                <p className="text-xl">
                                    {caseStudy.slogan}
                                </p>
                            </div>
                        </div>
                    </LinkDiv>
                </div>
            </div>
        </>
    )
}