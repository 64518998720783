import Router from "./Router";

function App() {
    return (
        <div className="App w-full overflow-x-hidden">
            <Router />
        </div>
    );
}

export default App;
