import React from 'react'

export const ShowcaseHero = ({image, name}) => {
    return (
        <div className="mx-auto px-6 py-8 md:py-28 text-center bg-black w-full md:h-screen">
            <div className="mx-auto max-w-3xl py-12">
                <p className="mt-6 text-primary text-lg">
                    UX Case Study
                </p>
                <h1 className="pt-6 text-3xl font-bold text-white leading-12 lg:text-4xl">
                    {name || 'N/A' }
                </h1>
            </div>
            {
                image 
                ? <div className='max-w-5xl w-full mt-3 sm:mt-8 mx-auto'>
                    <img src={image} alt="UCH App Sample" />
                </div>
                : null
            }
            
        </div>
    )
}