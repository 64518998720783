import React from "react";
import { Layout } from "../layouts";
import UserImage from "../assets/img/user-image.jpeg"
import ShapeLeft from "../assets/img/shape-left.png"
import ShapeRight from "../assets/img/shape.png"
import Perxels from "../assets/img/company-logo/perxels.png"
import Profectus from "../assets/img/company-logo/profectus.png"
import Reskill from "../assets/img/company-logo/reskill.png"
import Sanaa from "../assets/img/company-logo/sanaa.png"
import Tech1m from "../assets/img/company-logo/tech1m.png"
import Tedbree from "../assets/img/company-logo/tedbree.png"
import { motion } from "framer-motion";

const companyImages = [Tech1m, Tedbree, Perxels, Reskill, Profectus, Sanaa]

const xVariants = {
    left: { x: -150, transition: { staggerChildren: 0.1 }},
    right: { x: 150, transition: { staggerChildren: 0.1 }},
    small: { scale: 0, transition: { staggerChildren: 0.1 }},
    animate: { x: 0, scale:1, transition: { duration: 1, staggerChildren: 0.1, delayChildren: 0.3}},
    hidden: { y: -80, opacity: 0, },
    show: { 
        y: 0,
        opacity: 1,
    }
}

const Hero = () => {
    return (
        <div className="mx-auto px-6 py-28 text-center bg-black w-full">
            <motion.div
                variants={xVariants}
                className="mx-auto max-w-xl py-12"
            >
                <motion.h1 
                    initial="hidden" whileInView='show' 
                    transition={{ duration: 0.4, delay: 0.5}}
                    variants={xVariants} 
                    className="text-3xl font-bold text-white leading-12 lg:text-4xl"
                >
                    &ldquo; With Design I like 
                    <span className="text-primary"> how well I Can Help </span> Translate 
                    <span className="text-primary"> Ideas</span> &rdquo;
                </motion.h1>
            </motion.div>
            <motion.div variants={xVariants} className="max-w-lg h-auto mx-auto">
                <motion.img 
                    initial="hidden" whileInView='show' 
                    variants={xVariants} src={UserImage} 
                    transition={{ duration: 0.4, delay: 0.8}}
                    alt="Tobi Da-Silva" className="w-full" 
                />
            </motion.div>
        </div>
    )
}

const AboutContent = () => {
    return (
        <div className="bg-black text-white">
            <div className="items-center mx-auto container">
                
                <div className="px-4 bg-right pb-8 sm:pb-20">
                    <div className="py-4">
                        <div className="">
                            <div>
                                <motion.div variants={xVariants} className="py-4 flex justify-between items-baseline">
                                    <motion.img 
                                        variants={xVariants}
                                        initial='left' 
                                        whileInView='animate'
                                        src={ShapeLeft} alt="Horn Left"
                                        className="w-12 sm:w-20 h-auto hidden sm:flex"
                                    />

                                    <motion.h1 variants={xVariants} initial='hidden' whileInView='show' className="text-2xl font-bold text-primary leading-10 sm:leading-12">
                                        My skill set combines design with previous work experience.
                                    </motion.h1>

                                    <motion.img 
                                        variants={xVariants}
                                        initial='right' whileInView='animate'
                                        src={ShapeRight} alt="Horn Right" className="w-20 h-auto hidden sm:flex" 
                                    />
                                    
                                </motion.div>
                                <motion.div variants={xVariants}  className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                                    <motion.p variants={xVariants} initial='hidden' whileInView='show' className="pb-6">
                                        As an individual, I am very interested in user research and design 
                                        because it allows me to progress from an unknown problem to a fully 
                                        fleshed-out solution that has a positive impact on members of society.
                                    </motion.p>
                                    <motion.p variants={xVariants} initial='hidden' whileInView='show' className="pb-6">
                                        My previous roles as a graduate research assistant in biochemistry and quality control 
                                        officer emphasized the importance of data collection and analysis before arriving at a 
                                        conclusion when working on different samples .
                                    </motion.p>
                                    <motion.p variants={xVariants} initial='hidden' whileInView='show' className="pb-6">
                                        I also explored other interests such as content strategy and customer relationships management, 
                                        where  I was able to understand how to effectively communicate information to people of different 
                                        age groups via the art of storytelling.  My approach is to ensure simple, clear and concise 
                                        communication when interacting with the target audience.
                                    </motion.p>
                                    <motion.p variants={xVariants} initial='hidden' whileInView='show' className="pb-6">
                                        With all of my previous experience, it was seamless transitioning into digital product 
                                        design due to the overlapping areas between UX design and my previous skill set from other 
                                        jobs. As a digital product designer, I've honed core UI and UX design skills such as 
                                        wire-framing, design thinking, user research, interaction design, and usability testing.
                                    </motion.p>
                                    <motion.p variants={xVariants} initial='hidden' whileInView='show' className="pb-6">
                                        I like that my research and problem-solving skills allow me to contribute to 
                                        providing solutions and designing products that are human-centered and 
                                        optimize the user's daily life.
                                    </motion.p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Partners = () => {
    return (
        <div className="bg-black">
            <div className="text-center border-primary border-t container mx-auto py-4">
                <div className="py-6">
                    <motion.h2 variants={xVariants} initial='hidden' whileInView='show' className="text-2xl font-bold text-primary leading-10 sm:leading-12">
                        Selected Businesses I have Worked For
                    </motion.h2>
                </div>
                <div>
                    <div className="w-full">
                        <ul className="flex flex-wrap items-center justify-between">
                            {
                                companyImages.map((image, key) => (
                                    <motion.li variants={xVariants} className="w-full sm:w-1/3 lg:w-1/6" key={key}>
                                        <motion.img
                                            variants={xVariants} initial='left' whileInView='animate' 
                                            transition={{ duration: 0.4 * key, delay: 0.2 * key}}
                                            src={image} 
                                            alt="Angled front view with bag zipped and handles upright." 
                                            className="h-auto w-auto object-fit px-8"
                                        />
                                    </motion.li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const About = () => {
    return (
        <>
            <Layout>
                <Hero />

                <AboutContent />

                <Partners />
            </Layout>
        </>
    )
}