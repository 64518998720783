import React from 'react'
import { Link } from 'react-router-dom'

export const ShowcasePagination = ({ data={} }) => (
    <>
        <div className='container bg-white mx-auto py-12 px-4 sm:px-0'>
            <div className="border-primary border-t border-b py-8">
                <div className='flex items-center justify-between'>
                    <div>
                        <h1 className='text-2xl font-semibold pb-3'>
                            Next Project
                        </h1>
                        <p className="text-lg">
                            {data.title || "N/A"}
                        </p>
                    </div>
                    <div>
                        <Link to={data.link} className="arrow-link-2">
                            <svg width="49" height="32" viewBox="0 0 49 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_412_2906" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="49" height="32">
                                    <path d="M33.727 31.272L31.102 28.6811L41.9088 17.8743H0.679688V14.1243H41.9088L31.102 3.35156L33.727 0.726562L48.9997 15.9993L33.727 31.272Z" fill="#F0E0CB"/>
                                </mask>
                                <g mask="url(#mask0_412_2906)">
                                    <rect x="0.679688" y="0.724609" width="48.32" height="30.55" fill="currentColor"/>
                                </g>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
)