import React from 'react'

export const QuoteSection = ({ image, text='N/A'}) => (
    <>
        <div className='relative'>
            <img src={image} alt="Quote Background" />
            <div className='w-full absolute text-white top-8 sm:top-12'>
                <div className='max-w-4xl mx-auto sm:py-32 text-center px-2'>
                    <p className="py-2 sm:pt-6 text-sm sm:text-3xl font-bold text-white md:leading-12 lg:text-4xl">
                        "
                        {text}
                        "
                    </p>
                </div>
            </div>
        </div>
    </>
)