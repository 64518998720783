import React from 'react'
import { Layout } from '../layouts'
import { ShowcaseHero } from '../components/ShowcaseHero'
import HeroBanner from '../assets/img/mirror/herro.png'
import { ShowcaseOverviewSection } from '../components/ShowcaseOverviewSection'
import { overviewStats, overviewTexts, mirrorProblemOptions, mirrorNeedOptions, mirrorSolutionOptions } from '../contents/MirrorShowcase'
import QuoteBg from '../assets/img/mirror/quote-bg.png'
import UserInterviewImg from '../assets/img/mirror/user-interview.png'
import ShopGiveawayImg from '../assets/img/mirror/shop-giveaway.png'
import RetailMarkdownImg from '../assets/img/mirror/retail-markdown.png'
import CompetitiveAnalysisImg from '../assets/img/mirror/competitive-analysis.png'
import EmpathyMapImg from '../assets/img/mirror/empathy-map.png'
import UserPersonaImg from '../assets/img/mirror/user-persona.png'
import StatementTextImg from '../assets/img/mirror/statement-text.png'
import CardSortingImg from '../assets/img/mirror/card-sorting.png'
import MirrorSiteImg from '../assets/img/mirror/mirror-site.png'
import MirrorUserFlowImg from '../assets/img/mirror/mirror-user-flow.png'
import PaperSketchesImg from '../assets/img/mirror/sketch.png'
import LowFidelityImg from '../assets/img/mirror/low-fidelity.png'
import UIKitsImg from '../assets/img/mirror/ui-kits.png'
import HighFidelityImg from '../assets/img/mirror/high-fidelity.png'
import ParticipantsImg from '../assets/img/mirror/participants-img.png'
import { ShowcasePagination } from '../components/ShowcasePagination'
import { caseStudyLists } from '../contents/data'

const QuoteSection = () => (
    <>
        <div className='relative'>
            <img src={QuoteBg} alt="Quote Background" />
            <div className='w-full absolute text-white top-8 sm:top-12'>
                <div className='max-w-4xl mx-auto sm:py-32 text-center px-2'>
                    <p className="py-2 sm:pt-6 text-sm sm:text-3xl font-bold text-white md:leading-12 lg:text-4xl">
                        "
                        How do we provide an easy-to-use platform for both existing and new customers, while
                        also ensuring a positive user experience while browsing the website??""
                    </p>
                </div>
            </div>
        </div>
    </>
)

const BriefHistorySection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    About Mirror
                </h1>
            </div>
            <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                <p className="pb-6">
                    Mirror is a clothing store with a presence in over seven countries in Africa it was established 
                    to make clothing affordable and accessible to people of all age groups and class.
                </p>
                <p className="pb-6">
                    Mirror aspires to be the face of fashion on the African continent for the foreseeable future. 
                    Their vision is to make clothing accessible to all while maintaining style and luxury across 
                    all age groups.
                </p>
            </div>

            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    Business KPI
                </h1>
            </div>
            <div className="py-4 md:py-12 leading-9 text-xl md:text-2xl">
                <p className="pb-6">
                    The project's key performance indicators include developing a fully responsive website that 
                    can be accessed both on the web and on mobile devices, allowing consumers to 
                </p>
                <ul className="pb-6 list-disc ml-4">
                    <li>
                        easily learn about their online store,
                    </li>
                    <li>
                        purchase multiple clothing items from the store and 
                    </li>
                    <li>
                        make online payments easily and directly from the website.
                    </li>
                </ul>
                <p className="pb-6">
                    By accomplishing this target, clients would be able to shop clothing items from their store 
                    without having to come into the store physically thus, increasing sales by at least 60%.
                </p>
            </div>
        </div>
    </>
)

const UnderstandingProblemSection = () => (
    <>
        <div className="bg-orange-50 py-8 sm:py-24">
            <div className='container mx-auto'>
                <div className='py-2 text-center'>
                    <h1 className="text-2xl font-bold text-primary leading-12 lg:text-3xl">
                        Understanding the Problem
                    </h1>
                </div>

                <div>
                    <ul className='flex flex-wrap w-full pt-16'>
                    
                        {
                            mirrorProblemOptions.map((list, key) => (
                                <li className='w-full sm:w-1/2 my-8 sm:my-4' key={key}>
                                    <div className='bg-white py-2 mx-4 px-2 sm:px-8 md:px-12 rounded-md relative'>
                                        <div className='w-full absolute -top-8'>
                                            <div className='w-20 mx-auto overflow-x-hidden' dangerouslySetInnerHTML={{ __html: list.svg }}></div>
                                        </div>
                                        <div className='px-2 text-center py-6'>
                                            <p className='text-xl text-gray-800 font-semibold py-4'>
                                                {list.title}
                                            </p>
                                            <p className='text-md px-4 font-normal text-gray-600'>
                                                {list.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </div>
    </>
) 

const ResearchReportSection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        User Interview
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        The user interview was extremely beneficial in learning about the challenges our users 
                        face when shopping in our physical stores and how we can better serve them while leveraging 
                        technology for a better customer experience.                     
                    </p>
                    <p className="pb-6">
                        Six volunteers from the specified demographic, each with a different occupation, were interviewed. 
                        Conversations with interviewees assisted me in better understanding how to approach the design 
                        challenge.Among the main excerpts from the interview are:
                    </p>
                </div>
                <div className='py-6 w-full max-w-5xl mx-auto'>
                    <img alt='User Interview' src={UserInterviewImg} />
                </div>

                <div className='py-8'>
                    <div className='p-4 sm:p-4 md:p-12 bg-red-100 rounded-md relative'>
                        <div className='md:absolute w-32 h-auto sm:top-8 sm:right-16 mx-auto'>
                            <img src={ShopGiveawayImg} alt='character' />
                        </div>
                        <div className='py-12 w-full max-w-5xl'>
                            <h2 className='text-xl md:text-2xl py-3 sm:py-6'>Challenges faced during the interview</h2>
                            <p className='text-gray-600 py-2'>
                                Although user interviews offer a valuable opportunity to gain insights directly from customers, arranging interviews 
                                can prove to be difficult due to participant availability and scheduling. As this was a face-to-face interview, it became 
                                a cumbersome task to constantly follow up with participants to ensure they remembered to be present at the designated time 
                                and location. Despite the difficulty, I managed to find six participants who were eager to share the information required 
                                to aid my design decisions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Competitive Analysis
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        I conducted secondary market research to learn how various online retailers operate, 
                        and then I investigated direct and indirect competitors (SWOT analysis) to better identify 
                        trends and opportunities for our brand.
                    </p>
                    <p className='pb-6'>
                        After completing my research, I created preliminary personas based on secondary 
                        market research and competitor analysis.
                    </p>
                    <div className='py-6 w-full max-w-5xl mx-auto'>
                        <img alt='Competitive Analysis' src={CompetitiveAnalysisImg} />
                    </div>
                </div>

                <div className='py-8'>
                    <div className='p-4 sm:p-4 md:p-12 bg-red-100 rounded-md relative'>
                        <div className='md:absolute w-40 h-auto sm:top-8 sm:right-16 mx-auto'>
                            <img src={RetailMarkdownImg} alt='character' />
                        </div>
                        <div className='py-6 w-full max-w-5xl'>
                            <h2 className='text-xl md:text-2xl py-3 sm:py-6'>Quick Learning From Competitor Analysis</h2>
                        </div>
                        <div className='w-full max-w-5xl'>
                            <ul>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>Online Shopping is becoming more prominent</span>
                                    <p className='text-gray-600 py-2'>
                                        In 2022, the worldwide online apparel stores is projected to reach a staggering $5.7 trillion. 
                                        it's by far one of the most popular  e-commerce niches with further growth anticipated in the 
                                        coming years. This indicates that online retailers are finding online apparel stores to be a 
                                        lucrative opportunity.
                                    </p>
                                </li>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>Customers want comfort.</span>
                                    <p className='text-gray-600 py-2'>
                                        As a result of the pandemic, the adoption of online shopping as a viable alternative to visiting 
                                        physical stores has increased significantly. Customers have now come to appreciate the convenience 
                                        of shopping from home and would prefer to continue doing so if there is a reliable delivery system 
                                        in place to ensure prompt delivery of their purchases.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
)

const UserNeedsSection = () => (
    <>
        <div className="bg-blue-50 py-8 sm:py-24">
            <div className='container mx-auto'>
                <div className='py-2 text-center'>
                    <h1 className="text-2xl font-bold text-primary leading-12 lg:text-3xl">
                        Defining the Problem
                    </h1>
                </div>

                <div>
                    <ul className='flex flex-wrap w-full pt-16'>
                        {
                            mirrorNeedOptions.map((list, key) => (
                                <li className='w-full sm:w-1/2 my-8 sm:my-4' key={key}>
                                    <div className='bg-white py-2 mx-4 rounded-md relative'>
                                        <div className='w-full absolute -top-8'>
                                            <div className='w-20 mx-auto overflow-x-hidden' dangerouslySetInnerHTML={{ __html: list.svg }}></div>
                                        </div>
                                        <div className='px-2 text-center py-6 sm:px-8 md:px-16'>
                                            <p className='text-xl text-gray-800 font-semibold py-4'>
                                                {list.title}
                                            </p>
                                            <p className='text-md px-4 font-normal text-gray-600'>
                                                {list.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </div>
    </>
)

const EmpathyMapSection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Empathy Map
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-2">
                        Making an empathy map assisted me in summarizing the information from my user interviews 
                        in a way that could be useful in identifying patterns in how the interviewees think, feel, 
                        and act. 
                    </p>
                    <p className="pb-6">
                        The patterns observed here aided me in understanding the needs of the users and were 
                        also useful in developing my user persona.
                    </p>
                </div>
                <div className='py-6 w-full max-w-5xl mx-auto'>
                    <img alt='Empathy Map' src={EmpathyMapImg} />
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        User Persona
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        With all of this information, I created a user persona to serve as a reference 
                        point as I worked through the design sprint. Meet Jasmine, a Midwife who prefers 
                        shopping online due to her busy schedule.
                    </p>
                    <div className='py-6 w-full max-w-5xl mx-auto'>
                        <img alt='Competitive Analysis' src={UserPersonaImg} />
                    </div>
                </div>
            </div>
        </div>
    </>
)

const BrainstormingSection = () => (
    <>
        <div className="bg-green-50 py-8 sm:py-24">
            <div className='container mx-auto'>
                <div className='py-2 text-center'>
                    <h1 className="text-2xl font-bold text-primary leading-12 lg:text-3xl">
                        Brainstorming and Idea Generation
                    </h1>
                </div>

                <div>
                    <ul className='flex flex-wrap w-full pt-16'>
                        {
                            mirrorSolutionOptions.map((list, key) => (
                                <li className='w-full sm:w-1/2 my-8 sm:my-4' key={key}>
                                    <div className='bg-white py-4 mx-4 rounded-md relative'>
                                        <div className='px-2 sm:px-8 md:px-12 text-center py-6'>
                                            <p className='text-xl text-gray-800 font-semibold py-4'>
                                                {list.title}
                                            </p>
                                            <p className='text-md px-4 font-normal text-gray-600'>
                                                {list.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </div>
    </>
)

const CardSortingSection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Card Sorting
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        I performed card sorting to understand how our users would categorize products into 
                        different sections before proceeding to determine how content and elements would be 
                        structured on the site. This was especially helpful when creating the website's site map.
                    </p>
                </div>
                <div className='py-6 w-full max-w-5xl mx-auto'>
                    <img alt='Card Sorting' src={CardSortingImg} />
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Information Architecture
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Now that I had a clear understanding of the users' preferences for how information 
                        would be organized on the website, I designed the information architecture and user 
                        flows to help understand the paths a user would take within the product.
                    </p>
                    <div className='py-6 w-full max-w-5xl mx-auto'>
                        <img alt='Information Architecture' src={MirrorSiteImg} className="pb-4" />
                        <img alt='Information Architecture' src={MirrorUserFlowImg} />
                    </div>
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Paper Sketches
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Now that I have a clear understanding of my problem statement and how the site's content 
                        should be organized. I created three different versions of the home page and then met 
                        with the client to get feedback before moving on to other screens.
                    </p>
                    <div className='py-6 w-full max-w-5xl mx-auto'>
                        <img alt='Information Architecture' src={PaperSketchesImg} />
                    </div>
                </div>
            </div>

            <div className='bg-white py-2 container mx-auto'>
                <div className="py-4 md:py-8 text-lg">
                    <p className="pb-3">After speaking with the client, I was able to: </p>

                    <ul className='space-y-4 list-disc pl-4 sm:pl-8 text-lg'>
                        <li>
                            obtain their feedback on their preferred version of the homepage (version 3).
                        </li>

                        <li>
                            We then discussed the results of the card sorting exercise and how they can be used 
                            to create wireframes for other screens.
                        </li> 

                        <li>
                            I then created mid-fidelity wire frames for the homepage and other screens.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
)

const DesignFidelitySection = () => (
    <>
        <div className="bg-white container mx-auto pb-8 sm:pb-20 px-4 sm:px-0">
            <div className='py-4 leading-9 text-xl md:text-2xl max-w-5xl mx-auto text-center'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    The Design
                </h1>
            </div>

            <div className='py-6 container mx-auto'>
                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                    <iframe 
                        src="https://www.loom.com/embed/20707d646d5c4173aad06d04dc43022b?sid=70ec5b6b-4e38-4e89-b1fa-75a9f4449f7d" 
                        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen title='Videos'
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', }}>
                    </iframe>
                </div>
            </div>

            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Low Fidelity Design
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        I created low-fidelity mockups of my paper sketches to get a better sense of the website 
                        layout before adding more visual elements to the design.
                    </p>
                    <p className="pb-6">
                        It was critical to annotate these screens because it helped explain to the client why I 
                        used different layouts within each screen. While I worked on the other inner pages, 
                        I would only show the landing page for the purposes of this presentation.
                    </p>
                </div>
                <div className='py-6'>
                    <img alt='Low Fidelity Design' src={LowFidelityImg} className="pb-4" />
                    <img alt='Low Fidelity Design' src={UIKitsImg} />
                </div>
            </div>

            <div className='py-8 sm:pt-16'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        High Fidelity Design
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Now that I've outlined and approved all of the components, colors, and typography 
                        styles, I'm excited to show you the final result of my design process in high fidelity view.
                    </p>
                </div>
                <div className='py-6 w-full max-w-5xl mx-auto'>
                    <img alt='High Fidelity Design' src={HighFidelityImg} />
                </div>
            </div>

            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Getting Feedback
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Once i was done with the high fidelity designs, the questions i needed to address included;
                    </p>
                    <ol className='space-y-4 list-decimal pl-4 sm:pl-8 text-xl font-medium'>
                        <li>Did this design solve the right problem?</li>
                        <li>How easy is it to understand and navigate this product as a user?</li>
                        <li>What could be improved on before shipping to development?</li>
                    </ol>
                    <p className="py-6">
                        To answer these questions, I had to conduct usability testing. I created an affinity 
                        map based on my usability testing result to show participant feedback
                    </p>
                </div>
                <div className='py-6'>
                    <img alt='Participants and Color Tag Design' src={ParticipantsImg} />
                </div>
            </div>

            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Reflection
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Working on this project was a great learning experience for me, especially given the 
                        short time frame; I am looking forward to applying some of what I learned from this 
                        project to other projects while continuing to improve my approach to problem solving in 
                        the design space.
                    </p>
                    <ul className='space-y-4 list-disc pl-4 sm:pl-8 text-xl'>
                        <li>
                            I was able to understand why design should be an iterative process through my 
                            usability testing. Receiving user feedback prompted me to highlight specific areas of the designs for iteration.
                        </li>
                        <li>
                            Speaking with stakeholders throughout the design process ensured that I was designing 
                            with both the user's and the business's needs in mind.
                        </li>
                        <li>
                            Once i am done with iterations, i would then ensure proper documentation for effective 
                            hand off with the development team
                        </li>
                    </ul>
                    <p className="py-6 hidden">
                        To answer these questions, I had to conduct usability testing. I created an affinity 
                        map based on my usability testing result to show participant feedback
                    </p>
                </div>
            </div>
        </div>
    </>
)

export const MirrorShowcase = () => {
    return (
        <>
            <Layout>
                <ShowcaseHero name="Mirror" image={HeroBanner} />

                <ShowcaseOverviewSection stats={overviewStats} texts={overviewTexts} />

                <QuoteSection />

                <BriefHistorySection />

                <UnderstandingProblemSection />

                <ResearchReportSection />

                <UserNeedsSection />

                <EmpathyMapSection />

                <div>
                    <img alt='statement text' src={StatementTextImg} />
                </div>

                <BrainstormingSection />

                <CardSortingSection />

                <DesignFidelitySection />

                <ShowcasePagination data={caseStudyLists[2]} />
            </Layout>
        </>
    )
}