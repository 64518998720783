import React from 'react'
import { ShowcaseHero } from '../components/ShowcaseHero'
import { Layout } from '../layouts'
import HeroImg from '../assets/img/sweet/hero.png'
import { ShowcaseOverviewSection } from '../components/ShowcaseOverviewSection'
import { overviewStats, overviewTexts, quoteText, stakeholderInterviewContents, sweetcremeProblemOptions, sweetNeedOptions, feedbackContents } 
    from '../contents/SweetCremeShowcase'
import { QuoteSection } from '../components/QuoteSection'
import QuoteBg from '../assets/img/sweet/quotebg.png'
import CustomerSurveyImg from '../assets/img/sweet/customer-survey.png'
import CompetitiveAnalysisImg from '../assets/img/sweet/competitive-analysis.png'
import ProblemStatementImg from '../assets/img/sweet/user-business-goals.png'
import StatementTextImg from '../assets/img/sweet/statement-text.png'
import UserPersonaImg from '../assets/img/sweet/persona.png'
import CustomerJourneyMapImg from '../assets/img/sweet/customer-journey-map.png'
import SiteMapImg from '../assets/img/sweet/site-map.png'
import HighFidelityImg from '../assets/img/sweet/image.png'
import HighFidelityImage from '../assets/img/sweet/high_fidelity.png'
import LowFidelityImg from '../assets/img/sweet/low-fidelity.png'
import UIKitImg from '../assets/img/sweet/ui-kit.png'
import { ShowcasePagination } from '../components/ShowcasePagination'
import { caseStudyLists } from '../contents/data'
import CharacterImg from '../assets/img/sweet/character.png'
import CakeImg from '../assets/img/sweet/birthday-cake.png'
import CateringServiceImg from '../assets/img/sweet/catering-service.png'


const BriefHistorySection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    About sweet Creme
                </h1>
            </div>
            <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                <p className="pb-6">
                    Sweet creme is an online cake store that provides cakes, treats and other baked goods to 
                    their clients located primarily in Nigeria. Founded in 2018, their mission is to continue 
                    to provide top-notch baked cakes to both returning and new customers.
                </p>
                <p className="pb-6">
                    Sweet creme currently does not have a website. Instead, the company relies on Instagram, 
                    WhatsApp, and word of mouth to promote and retain customers. Potential clients who do not 
                    have these apps will be unable to view previous works, gain a proper understanding of 
                    the business, and successfully place an order, limiting their access to their services.
                </p>
            </div>

            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    Business KPI
                </h1>
            </div>
            <div className="py-4 md:py-12 leading-9 text-xl md:text-2xl">
                <p className="pb-6">
                    The project's key performance indicators are to create a fully responsive website that can 
                    be visited both on the web and on mobile, allowing consumers to
                </p>
                <ul className="pb-6 list-disc ml-4 sm:ml-8">
                    <li>
                        easily learn about the brand,
                    </li>
                    <li>
                        place cake orders, and  
                    </li>
                    <li>
                    book an appointment for special requests and packages.
                    </li>
                </ul>
                <p className="pb-6">
                    By accomplishing this target, clients would be able to place orders and request special 
                    deals straight from the website, increasing website sales by at least 40%.
                </p>
            </div>
        </div>
    </>
)

const UnderstandingProblemSection = () => (
    <>
        <div className="bg-indigo-50 py-8 sm:py-24">
            <div className='container mx-auto'>
                <div className='py-2 text-center'>
                    <h1 className="text-2xl font-bold text-primary leading-12 lg:text-3xl">
                        Understanding the Problem
                    </h1>
                </div>

                <div>
                    <ul className='flex flex-wrap w-full pt-16'>
                    
                        {
                            sweetcremeProblemOptions.map((list, key) => (
                                <li className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                    <div className='bg-white py-2 mx-4 rounded-md relative'>
                                        <div className='w-full absolute -top-8'>
                                            <div className='w-20 mx-auto overflow-x-hidden' dangerouslySetInnerHTML={{ __html: list.svg }}></div>
                                        </div>
                                        <div className='text-center py-6 px-2 sm:px-8 md:px-12'>
                                            <p className='text-xl text-gray-800 font-semibold py-4'>
                                                {list.title}
                                            </p>
                                            <p className='text-md px-4 font-normal text-gray-600'>
                                                {list.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </div>
    </>
) 

const ResearchReportSection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Stakeholder Interview
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        For me, the first step in understanding the business and its needs was to conduct a 
                        one-on-one interview in which the business owner was able to communicate her needs 
                        and expectations from the design sprint. Among the main excerpts from the interview are:                    
                    </p>
                </div>
                <div className='py-6'>
                    <div className='flex flex-wrap w-full'>
                        {
                            stakeholderInterviewContents.map((option, key) => (
                                <div className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                    <div className="h-full w-full">
                                        <div className="relative w-full flex items-center justify-center">
                                            <img src={option.img} className="mb-3 w-40 h-40 rounded-xl" alt="" />
                                        </div>
                                        <div className="my-3 px-1">
                                            <div className="mb-2 text-center py-1">
                                                <p className="text-lg font-bold text-navy-700">
                                                    {option.heading}
                                                </p>
                                            </div>
                                            <div className="mb-2 text-center md:px-8">
                                                <ul className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
                                                    {
                                                        option.contents.map((content, index) => (
                                                            <li className='my-2 list-item list-inside list-disc' key={index}>
                                                                {/* <span className='bg-gray-600 h-1 w-1 rounded-full'></span> */}
                                                                <span className='text-lg'>
                                                                    {content}
                                                                </span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                       
                    </div>

                    <div>
                        <div className='p-4 sm:p-4 md:p-12 bg-purple-100 rounded-md relative'>
                            <div className='md:absolute w-16 h-auto sm:top-6 sm:right-16 mx-auto'>
                                <img src={CharacterImg} alt='character' />
                            </div>
                            <div className='py-4'>
                                <h2 className='text-xl md:text-2xl py-2'>My Insights From the Interview</h2>
                                <p className='text-gray-600 py-2'>
                                    At the end of my interview, it was evident that having a website would aid the business in the following ways;
                                </p>
                            </div>
                            <div>
                                <ul>
                                    <li className='list-inside list-disc'>
                                        <span className='text-lg py-2'>It would improve the way she manages her customer database.</span>
                                        <p className='text-gray-600 py-2'>
                                            This website would make it easier for her to collect relevant information about new and 
                                            returning customers through their interaction with the contact us section of the website.
                                        </p>
                                    </li>
                                    <li className='list-inside list-disc'>
                                        <span className='text-lg py-2'>It would make tracking orders easier.</span>
                                        <p className='text-gray-600 py-2'>
                                            Another advantage the website would provide to sweet creme as a business is the ease of tracking their 
                                            orders through one channel, this makes it easier for leads from their social media channels to place 
                                            an order and track the progress successfully.
                                        </p>
                                    </li>
                                    <li className='list-inside list-disc'>
                                        <span className='text-lg py-2'>Promote the brand to wider audience.</span>
                                        <p className='text-gray-600 py-2'>
                                            We believe that an aesthetically pleasing website which is SEO optimised can help the business reach 
                                            a wider audience on the web leading to increased sales in the long run for the business.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Customer Survey
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        I also conducted a customer survey to learn how existing clients felt about 
                        the current channels they were using and what strategy would be best to improve 
                        their user experience.
                    </p>
                    <div className='py-8 max-w-4xl mx-auto w-full'>
                        <img alt='Customer Survey' src={CustomerSurveyImg} />
                    </div>
                    <div>
                        <p>Interview Insights</p>
                        <ul className="list-disc pl-4 sm:pl-8 space-y-3 py-4">
                            <li>
                                9 out of 16 participants indicated enthusiasm about patronizing the brand through 
                                their website page once it is available.
                            </li>
                            <li>
                                10 of the 16 participants mentioned the list of available products, such as 
                                cakes and desserts, as a feature they would want to see on the website.
                            </li>
                            <li>
                                Discounts and deals were mentioned by 13 of 16 participants as a feature they 
                                would like to see on the website.
                            </li>
                            <li>
                                8 of the 16 participants said there should be a way to submit special orders for loved 
                                ones.
                            </li>
                            
                        </ul>
                        <p>
                            Ultimately, the customer survey was very helpful in understanding the needs of 
                            the clients in terms of having a website that is both functional and appealing.
                            {/* client's */}
                        </p>
                    </div>
                </div>
            </div>

            <div className='py-4'>
                <div>
                    <div className='p-4 sm:p-4 md:p-12 bg-purple-100 rounded-md relative'>
                        <div className='md:absolute w-32 h-auto sm:top-6 sm:right-20 mx-auto'>
                            <img src={CakeImg} alt='character' />
                        </div>
                        <div className='py-4 max-w-4xl w-full'>
                            <h2 className='text-xl md:text-2xl py-2'>Fun Fact</h2>
                            <p className='text-gray-600 py-2 text-lg'>
                                Sweet Creme boasts more that 7000 engaged followers on social media and has fulfilled more than 
                                3000 orders in 2022 through their primary communication channels, which include Referral, 
                                Instagram and WhatsApp.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='pt-24'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Competitive Analysis
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        According to my market study and competitive analysis, the cake sector is extremely 
                        competitive. Many players in this market demand some level of innovation to differentiate 
                        themselves from their competitors.
                    </p>
                    <p>
                        To better understand how I might assist my customer, I conducted research on how 
                        other entrepreneurs in the market are faring. I documented my observations from small, 
                        medium, and large-scale business owners in this industry. and here's an overview of my 
                        findings
                    </p>
                    <div className='py-8 max-w-5xl mx-auto w-full'>
                        <img alt='Competitive Analysis' src={CompetitiveAnalysisImg} />
                    </div>
                </div>

                <div>
                    <div className='p-4 sm:p-4 md:p-12 bg-purple-100 rounded-md relative'>
                        <div className='md:absolute w-28 h-auto sm:top-6 sm:right-16 mx-auto'>
                            <img src={CateringServiceImg} alt='character' />
                        </div>
                        <div className='py-4'>
                            <h2 className='text-xl md:text-2xl py-2'>Quick Learning From Competitor Analysis</h2>
                        </div>
                        <div>
                            <ul>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>The cake industry is a billion dollars industry</span>
                                    <p className='text-gray-600 py-2'>
                                        Many event requires cakes and pastries all year round from vendors, and as such all round 
                                        the world bakery owners continue to provide values to their customers
                                    </p>
                                </li>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>It takes creativity to standout.</span>
                                    <p className='text-gray-600 py-2'>
                                        Due to the recent advances in the way cakes are presented, it is important that cake artists need 
                                        to show uniqueness for every client they work with.
                                    </p>
                                </li>
                                <li className='list-inside list-disc'>
                                    <span className='text-lg py-2'>It takes a combination of online presence and customer management.</span>
                                    <p className='text-gray-600 py-2'>
                                        While I would be designing a website that is easily accessible to users, every customer who interacts with 
                                        the business via the website and other existing channels should be handled in a professional yet friendly 
                                        manner to make the business stand out from their competitors.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

const UserNeedsSection = () => (
    <>
        <div className="bg-orange-50 py-8 sm:py-24">
            <div className='container mx-auto'>
                <div className='py-2 text-center'>
                    <h1 className="text-2xl font-bold text-primary leading-12 lg:text-3xl">
                        Defining the Problem and User Needs
                    </h1>
                </div>

                <div>
                    <ul className='flex flex-wrap w-full pt-16'>
                        {
                            sweetNeedOptions.map((list, key) => (
                                <li className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                    <div className='bg-white py-2 mx-4 rounded-md relative'>
                                        <div className='w-full absolute -top-8'>
                                            <div className='w-20 mx-auto overflow-x-hidden' dangerouslySetInnerHTML={{ __html: list.svg }}></div>
                                        </div>
                                        <div className='px-2 text-center py-6 sm:px-8 md:px-16'>
                                            <p className='text-xl text-gray-800 font-semibold py-4'>
                                                {list.title}
                                            </p>
                                            <p className='text-md px-4 font-normal text-gray-600'>
                                                {list.text}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </div>
    </>
)

const ProblemStatementSection = () => (
    <>
        <div className="bg-white py-8 sm:py-24 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Problem Statement
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Based on my research and interviews, I was able to synthesize the user and business 
                        goals while also emphasizing areas where the goals appeared to intersect. This aided 
                        me in developing the problem statement that you may have seen previously while reading 
                        through my case study.                     
                    </p>
                </div>
                <div className='py-8 max-w-5xl mx-auto w-full'>
                    <img alt='Problem Statement' src={ProblemStatementImg} /> 
                </div>
            </div>
        </div>
    </>
)

const UserPersonaSection = () => (
    <>
        <div className="bg-white py-8 sm:py-12 container mx-auto px-4 sm:px-0">
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        User Persona
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        With all of this knowledge, I constructed a user persona which would serve as my reference 
                        point as I progressed through the design sprint. Meet Josephine, an HR manager who enjoys 
                        giving cakes to her loved ones.                    
                    </p>
                </div>
                <div className='py-8 max-w-5xl mx-auto w-full'>
                    <img alt='User Persona' src={UserPersonaImg} className="pb-4" /> 
                    <img alt='Customer Journey Map' src={CustomerJourneyMapImg} /> 
                </div>
            </div>
            <div>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Site Map
                    </h1>
                </div>
                <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                    <p className="pb-6">
                        Up next, I created a site map for this project to demonstrate how we might structure 
                        the information on the website. Because this is a client project, I am only 
                        authorized to share a section of this .                   
                    </p>
                </div>
                <div className='py-8 max-w-5xl mx-auto w-full'>
                    <img alt='Site map' src={SiteMapImg} /> 
                </div>
            </div>
        </div>
    </>
)

const DesignFidelitySection = () => (
    <>
        <div className="bg-white pb-8 sm:pb-20 px-4 sm:px-0">
            <div className='pb-4 leading-9 text-xl md:text-2xl max-w-5xl mx-auto text-center'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    Ideation and Design Fidelity
                </h1>
            </div>

            <div className='py-6 container mx-auto'>
                <div style={{ position: "relative", paddingBottom: "56.25%", height: 0}}>
                    <iframe 
                        src="https://www.loom.com/embed/8e698c4f0d6e44e7a457e3c73f37f622?sid=72fe76c1-5f83-4094-b401-50a1b20c18d8" 
                        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen title='Videos' 
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    ></iframe>
                </div>
            </div>

            <div className='py-6 container mx-auto'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        Low Fidelity Designs
                    </h1>
                </div>
                <div className="py-4 leading-9 text-xl md:text-2xl w-full">
                    <p className="pb-6">
                        While sketching with a pen and paper to come up with some ideas for how some 
                        of the screens might look, I then developed low fidelity mockups to get a better 
                        sense of the website layout. This allowed me to swiftly iterate on some of my 
                        original concepts while also allowing me to explore new ideas.
                    </p>
                </div>
                <div className='py-8 max-w-5xl mx-auto w-full'>
                    <img alt='Low Fidelity Design' src={LowFidelityImg} className="pb-12 sm:pb-20" />
                    <img alt='Low Fidelity Design' src={UIKitImg} />
                </div>
            </div>

            <div className='py-12 container mx-auto'>
                <div className='border-l-2 border-primary pl-4 py-2'>
                    <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                        High Fidelity Designs
                    </h1>
                </div>
                <div className="py-4 leading-9 text-xl md:text-2xl w-full">
                    <p className="pb-6">
                        I was able to design the high fidelity designs after establishing the style guide 
                        and website structure. Although I am not permitted to share all screens, here are 
                        a few samples from the design.
                    </p>
                </div>
                <div className='py-8 max-w-4xl mx-auto w-full'>
                    <img alt='Low Fidelity Design' src={HighFidelityImage} />
                </div>
                <div className='py-8 max-w-5xl mx-auto w-full'>
                    <img alt='Low Fidelity Design' src={HighFidelityImg} />
                </div>
            </div>
        </div>
    </>
)

const StatementSection = () => (
    <>
         <div>
            <div className='relative text-primary'>
                <img alt='statement text' src={StatementTextImg} />
                <div className="absolute top-0 w-full">
                    <div className='max-w-4xl mx-auto w-full flex flex-col items-center py-1 sm:py-20'>
                        <h2 className='text-sm sm:text-2xl font-semibold leading-6'>Problem Statement</h2>
                        <p className='text-xs sm:text-2xl font-semibold sm:leading-7 sm:py-2 text-center'>
                            "How can we design a minimalistic, visually appealing website that explains what the company does while also 
                            aiding with client database management?"
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
)

const GettingFeedbackSection = () => (
    <>
        <div className="bg-white container mx-auto pb-8 sm:pb-20 px-4 sm:px-0">
            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    Getting Feedback
                </h1>
            </div>
            <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                <p className="py-6">
                    After finishing the high fidelity designs, I created a fully functional <span className='text-primary'>prototype </span>  
                    that allowed me to conduct <span className='text-primary'>usability testing</span>. At this point, I wanted to know what 
                    could be improved about the design and if there were any issues with the current design. 
                    Participants in the test included a business stakeholder, a senior designer to provide 
                    design critique, and new users interacting with the brand for the first time. The following 
                    are some of the key findings from the interview:.
                </p>
            </div>
            <div className='py-6'>
                <div className='flex flex-wrap w-full'>
                    {
                        feedbackContents.map((option, key) => (
                            <div className='w-full sm:w-1/3 my-8 sm:my-4' key={key}>
                                <div className="h-full w-full">
                                    <div className="relative w-full flex items-center justify-center">
                                        <img src={option.img} className="mb-3 w-40 h-40 rounded-xl" alt="" />
                                    </div>
                                    <div className="my-3 px-1">
                                        <div className="mb-2 text-center py-1">
                                            <p className="text-lg font-bold text-navy-700">
                                                {option.heading}
                                            </p>
                                        </div>
                                        <div className="mb-2 text-center md:px-8">
                                            <ul className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
                                                {
                                                    option.contents.map((content, index) => (
                                                        <li className='my-2 list-item list-inside list-disc' key={index}>
                                                            {/* <span className='bg-gray-600 h-1 w-1 rounded-full'></span> */}
                                                            <span className='text-lg'>
                                                                {content}
                                                            </span>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
                <div className='py-8'>
                    <div>
                        <div className='p-4 sm:p-4 md:p-12 bg-purple-100 rounded-md relative'>
                            <div className='md:absolute w-28 h-auto sm:top-6 sm:right-16 mx-auto'>
                                <img src={CateringServiceImg} alt='character' />
                            </div>
                            <div className='py-4 w-full max-w-5xl'>
                                <h2 className='text-xl md:text-2xl py-2'>Areas To Improve From Usability Studies</h2>
                                <p className='text-gray-600 py-2'>
                                    Conducting usability studies proved to be a captivating experience, as the participants (also current clients), 
                                    were enthusiastic about the design and provided valuable feedback. This feedback can now be utilized to make 
                                    further iterations to the design before handling it over to developers. Areas for improvement include
                                </p>
                            </div>
                            <div className='w-full max-w-5xl'>
                                <ul>
                                    <li className='list-inside list-disc'>
                                        <span className='text-lg py-2'>Exploring other navigation UI on the menu</span>
                                        <p className='text-gray-600 py-2'>
                                            A participant highlighted that the current tab on the menu page can be improved to a better design as 
                                            opposed to the button tabs on the page.
                                        </p>
                                    </li>
                                    <li className='list-inside list-disc'>
                                        <span className='text-lg py-2'>Pricing and Deals section.</span>
                                        <p className='text-gray-600 py-2'>
                                            Three individuals emphasized the significance of having service package prices displayed, stating that 
                                            it would expedite their decision-making process when selecting which service to buy. This adjustment 
                                            would be done in collaboration with the business owner.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white container mx-auto pb-8 sm:pb-20 px-4 sm:px-0">
            <div className='border-l-2 border-primary pl-4 py-2'>
                <h1 className="text-3xl font-bold text-primary leading-12 lg:text-4xl">
                    Reflection
                </h1>
            </div>
            <div className="py-4 md:py-8 leading-9 text-xl md:text-2xl">
                <p className="pb-6">
                    This project began with a conversation with the business owner, and with my ux 
                    research skills, I was able to identify where their thought patterns overlapped with users. 
                    Working on this project taught me the value of keeping both users' and businesses' 
                    needs in mind when designing for an optimized experience.                   
                </p>
                <p className="pb-6">
                    My next steps would be as follows:
                </p>
                <ul className='space-y-4 list-disc pl-4 sm:pl-8 text-xl'>
                    <li>
                        Prioritize design revisions based on usability testing feedback
                    </li>
                    <li>
                        Meet with the stakeholders to ensure all everything concerning the design is in sync with 
                        the business needs
                    </li>
                    <li>
                        Design the mobile responsive versions of the website to increase website accessibility for users
                    </li>
                    <li>
                        Conduct usability testing using responsive screens
                    </li>
                    <li>
                        Hand Off Designs to the developers
                    </li>
                </ul>
            </div>
        </div>
    </>
)

export const SweetCremeShowcase = () => {
    return (
        <>
            <Layout>
                <ShowcaseHero name="Sweet Creme Website Design" image={HeroImg} />
                <ShowcaseOverviewSection isHeight={true} stats={overviewStats} texts={overviewTexts} />
                <QuoteSection image={QuoteBg} text={quoteText}  />
                <BriefHistorySection />
                <UnderstandingProblemSection />
                <ResearchReportSection />
                <UserNeedsSection />
                <ProblemStatementSection />
                <StatementSection />
                <UserPersonaSection />
                <DesignFidelitySection />
                <GettingFeedbackSection />
                <ShowcasePagination data={caseStudyLists[0]} />
            </Layout>
        </>
    )
}