import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const variants = {
    slideIn: { x: '-100%', opacity: 0},
    hidden: { y: -50, opacity: 0, },
    show: { 
        y: 0,
        x: 0,
        opacity: 1,
    }
}

export const Footer = () => {
    const year = new Date().getFullYear()
    const IsActive = () => {
        let links = ['', 'about']
        let location = useLocation().pathname.split('/');
        return links.includes(location[1])
        // return location[1]
    }
    // IsActive()
    
    return (
        <>
            <footer className="w-full py-6 bg-black text-white">
                <div className={`${IsActive() ? 'border-primary border-t' : ''} text-center container mx-auto py-4`}>
                    <motion.div 
                        variants={variants} 
                        initial='hidden' whileInView='show'
                        transition={{ duration: 0.4, delay: 0.5}}
                        className="py-6"
                    >
                        <motion.p variants={variants} className="text-3xl font-bold text-white lg:text-4xl leading-8">
                            Say Hello 😊
                        </motion.p>
                    </motion.div>
                    <motion.div
                        variants={variants} 
                        initial='hidden' whileInView='show'
                        transition={{ duration: 0.4, delay: 0.5}}
                        className="px-4 sm:px-0"
                    >
                        <motion.p variants={variants} className="text-lg py-4">
                            Feel free to reach out to me via your preferred option
                        </motion.p>
                        <motion.div variants={variants} className="space-x-4 pt-5 flex justify-center">
                            <motion.a
                                variants={variants} 
                                target="_blank" rel="noreferrer" 
                                href="mailto:dasilva.oyin@gmail.com?subject = Contact Me&body = " 
                                className="link-bg text-primary border-primary border rounded-full py-2 px-4 w-48 font-semibold hover:text-gray-900"
                            >
                                Email
                            </motion.a>
                            <motion.a 
                                variants={variants}
                                href="https://www.linkedin.com/in/tobi-dasilva/" target="_blank" 
                                rel="noreferrer" className="link-bg text-primary border-primary border rounded-full py-2 px-4 w-48 font-semibold hover:text-gray-900"
                            >
                                LinkedIn
                            </motion.a>
                        </motion.div>
                    </motion.div>
                </div>
                <motion.div
                    variants={variants} 
                    initial='hidden' whileInView='show'
                    transition={{ duration: 0.4, delay: 0.5}}
                    className="text-center border-primary border-t mx-auto mt-8 py-6"
                >
                    <motion.p variants={variants} className="text-md text-primary">
                        &copy; {year}. All rights reserved - Tobi Da-silva
                    </motion.p>
                </motion.div>
            </footer>
        </>
    )
}