import {Routes, Route } from "react-router-dom"
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import ScrollToTop from "./ScrollToTop";
import { UCHShowcase } from "./pages/UCHShowcase";
import { MirrorShowcase } from "./pages/MirrorShowcase";
import { DesignLabShowcase } from "./pages/DesignLabShowcase";
import { SweetCremeShowcase } from "./pages/SweetCremeShowcase";
import { NotFound } from "./pages/NotFound";


const Router = () => {
    return (
        <div>
            <ScrollToTop>
                <Routes>
                    <Route path="/" index element={<Home />} />
                    <Route path="/about" index element={<About />} />
                    <Route path="/portfolio/uch" index element={<UCHShowcase />} />
                    <Route path="/portfolio/mirror" index element={<MirrorShowcase />} />
                    <Route path="/portfolio/designlab" index element={<DesignLabShowcase />} />
                    <Route path="/portfolio/sweet-creme" index element={<SweetCremeShowcase />} />
                    <Route path="/*" index element={<NotFound />} />
                </Routes>
            </ScrollToTop>
        </div>
    )
}


export default Router