import UCHHomeImage from "../assets/img/uch-home.png"
import DesignlabImage from "../assets/img/designlab-home.png"
import SweetCremeImage from "../assets/img/sweetcreme-home.png"
import SanaaImage from "../assets/img/sanaa-home.png"
import MirrorImage from "../assets/img/mirror-home.png"

export const caseStudyLists = [
    {
        title: 'UCH E Records',
        link: '/portfolio/uch',
        image: UCHHomeImage,
        slogan: 'A mobile application designed to enable patients request their medical records ahead of hospital appointments.',
    },
    {
        title: 'Designlab Dashboard Feature',
        link: '/portfolio/designlab',
        image: DesignlabImage,
        slogan: 'Dashboard designed to enhance student learning and facilitate navigation with the LMS',
    },
    {
        title: 'Sweet Creme Website Design',
        link: '/portfolio/sweet-creme',
        image: SweetCremeImage,
        slogan: 'Designing a website for a bakery shop with the aim of enhancing their digital visibility and expanding their online reach',
    },
    {
        title: 'Sanaa',
        link: 'https://www.behance.net/gallery/125879987/Sanaa-%28African-Art-Gallery%29',
        image: SanaaImage,
        slogan: 'Rethinking the way African art is marketed and sold online',
    },
    {
        title: 'Mirror',
        link: '/portfolio/mirror',
        image: MirrorImage,
        slogan: 'Designing a website for the purpose of facilitating e-commerce transactions',
    },
]